// @i18n

export const EVENTCODE_TLD = '@kncandidateday.it';
export const COUNTRY_NAME = 'Italia';
export const COUNTRY_SHORTCUT = 'IT';

export const MAX_EVENT_LENGTH = 14;

export const DATETIMES: any = {
  DATE_SHORT: 'DD MMM',
  DATE_LONG: 'DD MMM YYYY',
  DATE_MONTH_YEAR: 'MMM YYYY',
  DATE_REVERSE: 'YYYY-MM-DD',
  TIME_SHORT: 'HH:mm',
  TIME_LONG: 'HH:mm:ss',
  DATETIME_SHORT: 'DD MMM, HH:mm',
  DATETIME_LONG: 'DD MMM YYYY, HH:mm',
  DATETIME_FULL_REVERSE: 'YYYY-MM-DD HH:mm:ss',
  DATE_PRIMEPICKER_LONG: 'dd M yy',
  DATE_PRIMEPICKER_MONTH_YEAR: 'y',
  HOUR_FORMAT: 24,
  FIRST_DAY_OF_WEEK: 1
}

export const USER_ROLES: any = {
	administrator: 'Amministratore',
	genius: 'Genius',
	recruiter: 'Recruteur',
	'registration-clerk': 'Responsable de l\'enregistrement',
	'back-office': 'Back-Office',
	management: 'Direction',
};

export const META_ROLES: any = {
	user: 'User',
	admin: 'Amministratore',
	superuser: 'SuperUser'
};

export const CURRENCIES_SHORT: any = {
	USD: '$',
	GBP: '£',
	EUR: '€'
}

export const EVENT_TYPES: any = {
	// 'candidate-day': 'candidateDay',
	'candidate-friday': 'candidateFriday',
};

export const EVENT_STATUSES: any = {
	setup: 'configurazione',
	standby: 'standby',
	online: 'pubblicato',
	running: 'iniziare',
	finished: 'terminare',
	archived: 'archiviato',
};

export const EVENTDAY_STATUSES: any = {
	setup: 'configurazione',
	standby: 'Standby',
	running: 'iniziare',
	finished: 'terminare',
	archived: 'archiviato',
};

export const CANDIDATEEVENT_STATUSES: any = {
	registered: 'Profilo incompleto',
	noshow: 'NoShow',
	interview: 'Colloquio fissato',
	finished: 'terminare',
};

export const CANDIDATEEVENT_INTERVIEW_STATUSES: any = {
	hired: 'assunto',
	pooled: 'raccolto',
	rejected: 'rifiutato',
	declined: 'declinato',
};

export const CANDIDATEEVENT_LISTSTATUSES: any = {
	registered: 'Profilo incompleto',
	ready: 'Profilo completo',
  scheduled: 'scheda',
	noshow: 'NoShow',
	interview: 'Colloquio fissato',
	finished: 'terminare',
};

export const REASONS: any = {
	pooled: [
		'Il luogo di lavoro e la data di inizio non sono adatti al candidato',
		'Il profilo di lavoro ideale del reclutatore non è disponibile',
		'Il profilo di lavoro ideale del candidato non è disponibile',
		'Retribuzione richiesta troppo alta',
	],
	rejected: [
		'Non soddisfa i requisiti minimi generali',
		'Non soddisfa i requisiti minimi - luogo di lavoro',
		'Non soddisfa le qualifiche minime - anzianità lavorativa',
		'Non soddisfa le qualifiche minime - esperienza rilevante',
		'Non soddisfa le qualifiche minime - permesso di lavoro',
		'Retribuzione richiesta troppo alta',
	],
	declined: [
		'Preferisco non rispondere',
		'Ragioni personali',
		'Le condizioni non sono sufficientemente vantaggiose',
		'I benefit non sono attrattivi',
		'L\'orario lavorativo non è adatto',
		'Preferisco rimanere con il mio datore di lavoro attuale',
		'La distanza casa-lavoro è eccessiva',
		'Il luogo di lavoro non è attrattivo',
	],
};

export const ACTION_REASON: any = {
	'New Hire (NHR)': 'New Hire (NHR)',
	'Rehire (REH)': 'Rehire (REH)',
	'Transfer from Affiliate (HAF)': 'Transfer from Affiliate (HAF)',
};

export const REQ_EMPLOYMENT_TYPE: any = {
	Permanent: 'Permanent',
	Temporary: 'Temporary',
};

export const REQ_FULLPART_TIME: any = {
	'Full Time': 'Full Time',
	'Part Time': 'Part Time',
};
