import { Deserializable } from './deserializable.model';
import { EventModel } from './event.model';
import { HiringmanagerModel } from './hiringmanager.model';

export class JoblocationModel implements Deserializable {
	// columns
	id: string;
	name: string;
	address_street: string;
	address_additional: string;
	address_zip: string;
	address_city: string;
	address_state: string;
	address_country: string;
	address_lat: number;
	address_lon: number;
	external_id: string;
	external_name: string;

  hiringmanager: HiringmanagerModel;
  hiringmanager_id: string;

	select_value: string; // @todo temp for recruiter_dashboard

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	get address_cityzipstate(): string {
    let address = this.address_city;
    if (this.address_state || this.address_zip) {
      address += ', ';
      // if (this.address_state) {
      //   address += this.address_state + ' ';
      // }
      address += this.address_zip;
    }
		return address;
	}

	get address_adminselect(): string {
		return this.name + ', ' + this.address_street + ', ' + this.address_city + ', ' + this.address_zip;
	}

}



