import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { UiService } from '../../services/ui.service';

import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	events: EventModel[] = null;
	nextEvent: EventModel = null;
	loading = true;

	constructor(
		public app: AppService,
		private apiService: ApiService,
		private dataService: DataService,
		private ui: UiService,
		private route: ActivatedRoute,
	  public transloco: TranslocoService
  ) { }

	ngOnInit() {
		let mode: string = this.route.snapshot.queryParamMap.get('m') || '';
		const eventid = this.route.snapshot.paramMap.get('eventid') || null;
		const jobtypeid = this.route.snapshot.paramMap.get('jobtypeid') || null;
		const marketingsource = this.route.snapshot.paramMap.get('marketingsource');
		if (marketingsource !== null) {
			this.dataService.setMarketingSource(marketingsource);
		}
		// location mode
		if (this.route.snapshot.url.find(segment => segment.path === 'loc') !== undefined) {
			mode = 'locations';
		}
		this.apiService.getEvents(mode, eventid, marketingsource)
			.subscribe((events) => {
				this.loading = false;
				this.events = events.filter((event: EventModel) => {
					return !event.is_searay;
				});
				if (this.app.showTopEvent() && this.events.length > 0) {
					this.nextEvent = this.events.shift();
				}
				if (eventid !== null && mode !== 'locations') {
					const selected = events.find(event => event.id == eventid);
					if (selected !== undefined) {
            if (jobtypeid) {
              const jobtype = selected.jobtypes.find(jobtype => jobtype.id == jobtypeid);
              this.ui.setEventDetail(selected, 'jobs', jobtype);
            } else {
              this.ui.setEventDetail(selected, 'overview');
            }
					} else if (this.nextEvent?.id == eventid) {
						this.ui.setEventDetail(this.nextEvent, 'overview');
					}
				}
		});
	}

}
