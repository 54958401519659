<ng-container *transloco="let t;">
<form class="uk-form-stacked" novalidate (submit)="next()">
  <div class="uk-modal-body">
    <div uk-grid class="uk-grid-divider">
      <div class="uk-width-1-2@m">
		<h2>{{ t('partials.preregistration.prereg_friday.personal_data.informations_personnelles') }}</h2>
		<app-messagelist [group]="'personal'"></app-messagelist>
		<div uk-grid class="uk-grid-small">
			<div [ngClass]="{'uk-width-1-3@m': event.is_searay, 'uk-width-1-2@m': !event.is_searay}">
				<sb-forms-input [control]="form.get('firstname')" [label]="t('common.firstname')"></sb-forms-input>
			</div>
			<div *ngIf="event.is_searay" class="uk-width-1-3@m">
				<sb-forms-input [control]="form.get('middlename')" [label]="t('common.middlename')"></sb-forms-input>
			</div>
			<div [ngClass]="{'uk-width-1-3@m': event.is_searay, 'uk-width-1-2@m': !event.is_searay}">
				<sb-forms-input [control]="form.get('lastname')" [label]="t('common.lastname')"></sb-forms-input>
			</div>
			<div class="uk-width-1-1@m">
				<sb-forms-input [control]="form.get('address_street')" [label]="t('common.address')"></sb-forms-input>
				<sb-forms-zipcity
					[zipControl]="form.get('address_zip')"
					[cityControl]="form.get('address_city')"
				></sb-forms-zipcity>
			</div>
			<div class="uk-width-1-2@m">
				<sb-forms-phone [control]="form.get('mobile')" [label]="t('common.mobile')"></sb-forms-phone>
			</div>
			<div class="uk-width-1-2@m">
				<sb-forms-input [control]="form.get('email')" [label]="t('common.email')"></sb-forms-input>
			</div>
		</div>
		<p class="uk-text-small">{{ t('common.privacypolicy') }}</p>
		<p class="uk-text-small"><a href="/downloads/privacypolicy" target="_blank"><span uk-icon="icon: album;"></span> {{ t('partials.preregistration.prereg_friday.personal_data.see_privacy_policy') }}</a></p>
		<sb-forms-checkbox [control]="form.get('privacy')" [label]="t('partials.preregistration.prereg_friday.personal_data.i_accept_terms_and_conditions')" [cbfirst]="true"></sb-forms-checkbox>
      </div>
      <div class="uk-width-1-2@m">
        <app-slotselector [event]="event" (set)="setSlot($event)"></app-slotselector>
      </div>
    </div>
  </div>
  <div class="uk-modal-footer">
    <div uk-grid>
      <div class="uk-width-1-2@m">
        <p class="uk-text-small"><span uk-icon="icon: info"></span> {{ t('partials.preregistration.prereg_friday.personal_data.fill_in_necessary_fields') }}</p>
      </div>
      <div class="uk-width-1-2@m uk-text-right">
        <button type="button" class="uk-button kn-button uk-modal-close">{{ t('common.cancel') }}</button>&nbsp;
        <!-- <button type="submit" class="uk-button kn-call-to-action" [disabled]="!form.valid">{{ t('common.next') }}</button> -->
        <button type="submit" class="uk-button kn-call-to-action">{{ t('common.next') }}</button>
      </div>
    </div>
  </div>
</form>

</ng-container>
