import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { VALIDATOR_PATTERN_NAME, VALIDATOR_PATTERN_MOBILE, VALIDATOR_PATTERN_EMAIL, VALIDATOR_PATTERN_ZIP } from '../../../../services/data.service';
import { EventModel } from '../../../../../../../sb-lib/src/lib/models/event.model';
import { FormhelperService } from '../../../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';

@Component({
	selector: 'app-personal-data',
	templateUrl: './personal-data.component.html',
	styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit, OnChanges {

	@Input() event: EventModel;
	@Input() data: any;
	@Input() errors: any;

	@Output() dataChange = new EventEmitter();

	form = new UntypedFormGroup({
		lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		middlename: new UntypedFormControl(''),
		firstname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		mobile: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MOBILE)]),
		email: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
		address_street: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		address_zip: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_ZIP)]),
		address_city: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		privacy: new UntypedFormControl('', Validators.required),
	});

	constructor(
		private fh: FormhelperService
	, public transloco: TranslocoService) { }

	ngOnInit() {
	}

	ngOnChanges(values: any) {
		if (values.errors.currentValue !== undefined && values.errors.currentValue.email !== undefined) {
			this.form.get('email').setErrors(values.errors.currentValue.email);
		}
		if (values.errors.currentValue !== undefined && values.errors.currentValue.mobile !== undefined) {
			this.form.get('mobile').setErrors(values.errors.currentValue.mobile);
		}
	}

	setSlot(slot: any) {
		this.data.candidate.slot = slot;
	}

	next() {
		if (this.form.valid) {
			this.setData((this.event.is_searay === true) ? 4 : 2);
			this.dataChange.emit(this.data);
		} else {
			this.fh.validateAllFormFields(this.form);
		}
	}

	private setData(step: any) {
		this.data.step = step;
		this.data.candidate.personal = {
			lastname: this.form.get('lastname').value,
			firstname: this.form.get('firstname').value,
			mobile: this.fh.phoneToValue(this.form.get('mobile').value),
			email: this.form.get('email').value,
			address_street: this.form.get('address_street').value,
			address_zip: this.form.get('address_zip').value,
			address_city: this.form.get('address_city').value,
			privacy: this.form.get('privacy').value,
		};
		if (this.event.is_searay === true) {
			this.data.candidate.personal.middlename = this.form.get('middlename').value;
		}
	}

}
