import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { VALIDATOR_PATTERN_NAME, VALIDATOR_PATTERN_MOBILE, VALIDATOR_PATTERN_EMAIL, DataService } from '../../../services/data.service';

import { SlotModel } from '../../../../../../sb-lib/src/lib/models/slot.model';
import { FormhelperService } from '../../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';

import { PreregistrationComponent } from '../preregistration.component';

@Component({
	selector: 'app-prereg-day',
	templateUrl: './prereg-day.component.html',
	styleUrls: ['./prereg-day.component.scss']
})
export class PreregDayComponent extends PreregistrationComponent implements OnInit {

	registrationSuccess = false;
	registrationLoading = false;
	registrationDeepLink: string = null;

	commonErrors: [];

	// form data
	registerForm = new UntypedFormGroup({
		lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		firstname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		mobile: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MOBILE)]),
		email: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
		privacy: new UntypedFormControl('', Validators.required),
		bookslot: new UntypedFormControl('no'),
		slot: new UntypedFormControl(''),
	});

	constructor(
		private api: ApiService,
		private dataService: DataService,
		private fh: FormhelperService
	, public transloco: TranslocoService) {
		super();
	}

	ngOnInit() {
		this.registerForm.patchValue({
			bookslot: 'no'
		});
	}

	setSlot(slot: SlotModel) {
		this.registerForm.patchValue({
			slot: slot
		});
	}

	register() {
		this.commonErrors = [];
		this.registrationSuccess = false;
		this.registrationLoading = true;
		const payload = {
			event: this.event.id,
			lastname: this.registerForm.value.lastname,
			firstname: this.registerForm.value.firstname,
			mobile: this.fh.phoneToValue(this.registerForm.value.mobile),
			email: this.registerForm.value.email,
			privacy: this.registerForm.value.privacy,
			slot: (this.registerForm.value.bookslot === 'yes') ? this.registerForm.value.slot : null,
			marketing_source: this.dataService.getMarketingSource()
		};
		this.api.register(payload)
			.subscribe(
				(result) => {
					this.registrationLoading = false;
					this.registrationSuccess = true;
					if (result.deeplink !== null) {
						this.registrationDeepLink = result.deeplink;
					}
					this.registerForm.reset();
				},
				error => {
					this.registrationLoading = false;
					this.registrationSuccess = false;
					this.commonErrors = error.common;
					const errors = error.fields;
					for (const key in errors) {
						if (!errors.hasOwnProperty(key)) { continue; }
						const message = errors[key].concat('<br>');
						this.registerForm.controls[key].setErrors({
							required: message
						});
					}
				}
			);
	}

	// getters for form validation
	get firstname() { return this.registerForm.get('firstname'); }
	get lastname() { return this.registerForm.get('lastname'); }
	get mobile() { return this.registerForm.get('mobile'); }
	get email() { return this.registerForm.get('email'); }
	get privacy() { return this.registerForm.get('privacy'); }

}
