import { ToastModule } from 'primeng/toast';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core'; // @todo ERSETZEN
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { Nl2BrPipeModule } from 'nl2br-pipe';

import { SbLibModule } from 'projects/sb-lib/src/public-api';
import { TranslocoRootModule } from 'projects/sb-lib/src/lib/modules/transloco/transloco-root.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { HeaderComponent } from './partials/layout/header/header.component';
import { NavbarComponent } from './partials/layout/navbar/navbar.component';
import { FooterComponent } from './partials/layout/footer/footer.component';
import { LoginComponent } from './partials/layout/login/login.component';
import { EventdetailComponent } from './partials/eventdetail/eventdetail.component';
import { MyprofileComponent } from './pages/profile/myprofile/myprofile.component';
import { MyeventsComponent } from './pages/profile/myevents/myevents.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderComponent } from './partials/layout/loader/loader.component';
import { UiComponent } from './partials/layout/ui/ui.component';
import { EventComponent } from './partials/event/event.component';
import { PassComponent } from './pages/pass/pass.component';
import { MyeventComponent } from './pages/profile/myevents/myevent/myevent.component';
import { MessagelistComponent } from './partials/messagelist/messagelist.component';
import { SlotselectorComponent } from './partials/slotselector/slotselector.component';
import { JobselectorComponent } from './partials/jobselector/jobselector.component';
import { SkillselectorComponent } from './partials/skillselector/skillselector.component';
import { OffcanvasComponent } from './partials/layout/offcanvas/offcanvas.component';
import { PreregistrationComponent } from './partials/preregistration/preregistration.component';
import { PreregDayComponent } from './partials/preregistration/prereg-day/prereg-day.component';
import { PreregFridayComponent } from './partials/preregistration/prereg-friday/prereg-friday.component';
import { PreregSearayComponent } from './partials/preregistration/prereg-searay/prereg-searay.component';
import { PersonalDataComponent } from './partials/preregistration/prereg-friday/personal-data/personal-data.component';
import { SlotComponent } from './partials/preregistration/prereg-friday/slot/slot.component';
import { WorkHistoryComponent } from './partials/preregistration/prereg-friday/work-history/work-history.component';
import { JobSkillsComponent } from './partials/preregistration/prereg-friday/job-skills/job-skills.component';
import { OfferletterComponent } from './pages/offerletter/offerletter.component';
import { ListselectorComponent } from './partials/listselector/listselector.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContentComponent } from './pages/content/content.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		SearchComponent,
		HeaderComponent,
		NavbarComponent,
		FooterComponent,
		LoginComponent,
		EventdetailComponent,
		MyprofileComponent,
		MyeventsComponent,
		LoaderComponent,
		UiComponent,
		EventComponent,
		PassComponent,
		MyeventComponent,
		MessagelistComponent,
		SlotselectorComponent,
		JobselectorComponent,
		SkillselectorComponent,
		OffcanvasComponent,
		PreregistrationComponent,
		PreregDayComponent,
		PreregFridayComponent,
		PreregSearayComponent,
		PersonalDataComponent,
		SlotComponent,
		WorkHistoryComponent,
		JobSkillsComponent,
		OfferletterComponent,
		ListselectorComponent,
    ContentComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		// FlatpickrModule.forRoot(),
		MomentModule,
		FileUploadModule,
		NgxPageScrollCoreModule,
		RoundProgressModule,
		Nl2BrPipeModule,
		SbLibModule,
    TranslocoRootModule,
    ConfirmDialogModule,
    ToastModule,
    FontAwesomeModule,
    GoogleMapsModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: MessageService,
    },
    {
      provide: ConfirmationService,
    },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
