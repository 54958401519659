import { Injectable } from '@angular/core';
import { ApiCoreService } from 'projects/sb-lib/src/lib/modules/api/services/api-core.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'projects/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService extends ApiCoreService {

  constructor(
		public http: HttpClient,
	) {
		super(http, null, environment);
	}

  changePassword(payload: any): Observable<any> {
		return this.http
			.post<any>(this.getUrl('change-password'), payload)
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

}
