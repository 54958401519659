<ng-container *transloco="let t;">
<div class="uk-container">

	<div class="uk-section">
		<h1>{{ t('profile.myevents.my_events') }}</h1>

		<app-messagelist></app-messagelist>

		<app-loader [mode]="loadingMode" [messageLoading]="t('profile.myevents.loading_your_events')" [messageError]="t('profile.myevents.events_not_loaded')"></app-loader>

		<div *ngIf="loadingMode === null">

			<div *ngIf="candidate !== null" class="uk-margin">
				<h2>{{ t('profile.myevents.searching_events') }}</h2>
				<p>{{ t('profile.myevents.specify_radius') }}</p>
				<p *ngIf="radiusMode === true">{{ t('profile.myevents.change_radius_slider') }}</p>
				<div class="radius">
					<form class="uk-form-stacked" [formGroup]="radiusForm" (submit)="setSearchradius()" novalidate>
						<div uk-grid>
							<div class="uk-width-expand@m">
								<span uk-icon="icon: location;"></span>
								<span *ngIf="candidate.search_radius === null || candidate.search_radius === 0">{{ t('profile.myevents.event_search_disabled') }}</span>
								<span *ngIf="candidate.search_radius !== null && candidate.search_radius > 0">{{ t('profile.myevents.radius_is') }} <strong>{{candidate.search_radius}} {{ t('profile.myevents.radius_unit') }}</strong>.</span>
							</div>
							<div class="uk-width-expand@m" *ngIf="radiusMode === true">
								<input class="uk-range" type="range" formControlName="radius" min="0" max="50" step="5">
							</div>
							<div class="uk-width-auto@m uk-text-right">
								<a *ngIf="radiusMode !== true" (click)="setRadiusMode()"><span uk-icon="icon: pencil;"></span> {{ t('profile.myevents.change_search_radius') }}</a>
								<sb-ui-button *ngIf="radiusMode === true" [title]="t('common.cancel')" [small]="true" (click)="cancelRearchRadius()"></sb-ui-button>&nbsp;<sb-forms-submitbutton *ngIf="radiusMode === true" [title]="t('common.save')" [small]="true" [submitting]="radiusSending === true"></sb-forms-submitbutton>
							</div>
						</div>
					</form>
				</div>
				<hr>
			</div>

			<h2>{{ t('profile.myevents.list_of_events') }}</h2>

			<div *ngIf="candidateevents !== null && candidateevents.length > 0">
				<div *ngFor="let candidateevent of candidateevents">
					<app-myevent [candidateevent]="candidateevent" (unregistered)="unregister($event)"></app-myevent>
				</div>
			</div>
			<div *ngIf="candidateevents === null || candidateevents.length === 0">
				<p>{{ t('profile.myevents.not_registered') }}</p>
				<p>{{ t('profile.myevents.go_to') }} <a [routerLink]="['/']">{{ t('profile.myevents.home') }}</a> {{ t('profile.myevents.or') }} <a [routerLink]="['/search']">{{ t('profile.myevents.search_event_1') }}</a> {{ t('profile.myevents.search_event_2') }}</p>
			</div>
		</div>

	</div>

</div>

</ng-container>
